/* eslint-disable */

<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <datepicker
          placeholder="Select Date"
          :format="customFormatter"
          v-model="startDate"
        ></datepicker>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <vs-select
          class="selectExample"
          v-model="teamV_mmodel"
          placeholder="Select Spoc Team"
        >
          <vs-select-item
            :key="index"
            :value="item.text"
            :text="item.text"
            v-for="(item, index) in team"
          />
        </vs-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-card>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
              <h3>
                {{ selectedTeam }}
              </h3>
            </vs-col>
            <vs-col
              style="display:initial"
              vs-justify="end"
              vs-align="end"
              vs-w="6"
            >
              <div class="vx-row sm:flex hidden mt-4">
                <div class="vx-col w-full flex justify-end">
                  <!-- Labels -->
                  <div class="flex flex-wrap sm:justify-end justify-end">
                    <div class="flex items-center mr-4 mb-2">
                      <div
                        class="h-3 w-3 inline-block rounded-full mr-2 bg-success"
                      ></div>
                      <span>Present</span>
                    </div>
                    <div class="flex items-center mr-4 mb-2">
                      <div
                        class="h-3 w-3 inline-block rounded-full mr-2 bg-warning"
                      ></div>
                      <span>Came Late</span>
                    </div>
                    <div class="flex items-center mr-4 mb-2">
                      <div
                        class="h-3 w-3 inline-block rounded-full mr-2 bg-warning"
                      ></div>
                      <span>Left Early</span>
                    </div>
                    <div class="flex items-center mr-4 mb-2">
                      <div
                        class="h-3 w-3 inline-block rounded-full mr-2 bg-warning"
                      ></div>
                      <span>Half Day</span>
                    </div>
                    <div class="flex items-center mr-4 mb-2">
                      <div
                        class="h-3 w-3 inline-block rounded-full mr-2 bg-danger"
                      ></div>
                      <span>Absent</span>
                    </div>
                  </div>
                </div>
              </div>
            </vs-col> </vs-row
          ><br />

          <vs-row style="overflow-x: auto">
            <vs-col
              vs-type="flex"
              vs-justify="start"
              vs-align="start"
              vs-w="12"
            >
              <vs-table
                stripe
                style="margin:auto; width: 100%;"
                :data="newSpocData"
              >
                <template slot="thead">
                  <vs-th class="fixed-side" scope="col">
                    Spoc
                  </vs-th>
                  <vs-th
                    scope="col"
                    v-for="(item, indexo) in selectedWeekDays"
                    :key="indexo"
                  >
                    {{ customFormatterForHeader(item) }}
                  </vs-th>
                </template>

                <template>
                  <vs-tr v-for="(item, index) in newSpocData" :key="index">
                    <vs-th class="fixed-side" scope="col">{{
                      item.spoc_name
                    }}</vs-th>
                    <vs-th
                      v-for="(newdata, index) in item.attendance"
                      :key="index"
                    >
                      <div
                        @click="
                          newdata.exists == 0
                            ? onChange(item, newdata.unix_timestamp, newdata)
                            : onChangeEdit(
                                item,
                                newdata.unix_timestamp,
                                newdata
                              )
                        "
                      >
                        <span
                          ><vs-icon
                            size="small"
                            :icon="getIcon(newdata.status)"
                            :color="getColor(newdata.status)"
                          ></vs-icon
                        ></span>
                      </div>
                      <!-- {{ newdata.status }} -->
                    </vs-th>

                    <!-- <vs-td v-for="(newdate, index) in selectedWeekDays" :key="index">
                      <div v-for="set in item.days">
                        <div
                          v-if="
                            set.attendance_date === newdate &&
                              set.status === 'present'
                          "
                          style="text-align: center; "
                        >
                          <div
                            class="tooltip"
                            @click="onChangeEdit(item, newdate, set)"
                          >
                            <span
                              ><vs-icon
                                size="small"
                                icon="done"
                                color="green"
                              ></vs-icon
                            ></span>
                            <span class="tooltiptext">{{ set.comment }}</span>
                          </div>
                        </div>
                        <div
                          v-if="
                            set.attendance_date === newdate &&
                              set.status === 'absent'
                          "
                          style="text-align: center; "
                        >
                          <div
                            class="tooltip"
                            @click="onChangeEdit(item, newdate, set)"
                          >
                            <span
                              ><vs-icon
                                size="small"
                                icon="cancel"
                                color="danger"
                              ></vs-icon
                            ></span>
                            <span class="tooltiptext">{{ set.comment }}</span>
                          </div>
                        </div>
                        <div
                          v-if="
                            set.attendance_date === newdate &&
                              set.status === 'default'
                          "
                          style="text-align: center; "
                        >
                          <div
                            class="tooltip"
                            @click="onChange(item, newdate, set)"
                          >
                            <span
                              ><vs-icon
                                size="small"
                                icon="cancel"
                                color="danger"
                              ></vs-icon
                            ></span>
                            <span class="tooltiptext">{{ set.comment }}</span>
                          </div>
                        </div>

                        <div
                          v-if="
                            set.attendance_date === newdate &&
                              set.status === 'came_late'
                          "
                          style="text-align: center; "
                        >
                          <div
                            class="tooltip"
                            @click="onChangeEdit(item, newdate, set)"
                          >
                            <span
                              ><vs-icon
                                size="small"
                                icon="done"
                                color="warning"
                              ></vs-icon
                            ></span>
                            <span class="tooltiptext">{{ set.comment }}</span>
                          </div>
                        </div>
                        <div
                          v-if="
                            set.attendance_date === newdate &&
                              set.status === 'left_early'
                          "
                          style="text-align: center; "
                        >
                          <div
                            class="tooltip"
                            @click="onChangeEdit(item, newdate, set)"
                          >
                            <span
                              ><vs-icon
                                size="small"
                                icon="done"
                                color="warning"
                              ></vs-icon
                            ></span>
                            <span class="tooltiptext">{{ set.comment }}</span>
                          </div>
                        </div>
                      </div>
                    </vs-td> -->
                  </vs-tr>
                </template>
              </vs-table>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-popup
      class="holamundo"
      :title="
        unixTimestampConvert(this.spocAttendanceDate) + ', ' + this.spocName
      "
      :active.sync="attendancePopup"
    >
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="present"
            >Present</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="came_late"
            >Came Late</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="left_early"
            >Left Early</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="half_day"
            >Half Day</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="absent"
            >Absent</vs-radio
          >
        </vs-col> </vs-row
      ><br />
      <vs-row>
        <vs-col vs-type="flex" vs-w="12">
          <vs-textarea
            width="100%"
            v-model="attendanceComment"
            placeholder="Comment"
          />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="9">
        </vs-col>
        <vs-col vs-type="flex" vs-justify="end" vs-align="end" vs-w="3">
          <vs-button
            color="primary"
            size="small"
            @click="addAttendance()"
            type="filled"
            >Submit</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
    <!-- ============== -->

    <vs-popup
      class="holamundo"
      :title="
        unixTimestampConvert(this.editSpocAttendanceDate) + ', ' + this.spocName
      "
      :active.sync="editAttendancePopup"
    >
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-radio
            v-model="editAttendanceRadio"
            vs-name="attendanceRadio"
            vs-value="present"
            >Present</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-radio
            v-model="editAttendanceRadio"
            vs-name="attendanceRadio"
            vs-value="came_late"
            >Came Late</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-radio
            v-model="editAttendanceRadio"
            vs-name="attendanceRadio"
            vs-value="left_early"
            >Left Early</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-radio
            v-model="editAttendanceRadio"
            vs-name="attendanceRadio"
            vs-value="half_day"
            >Half Day</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-radio
            v-model="editAttendanceRadio"
            vs-name="attendanceRadio"
            vs-value="absent"
            >Absent</vs-radio
          >
        </vs-col> </vs-row
      ><br />
      <vs-row>
        <vs-col vs-type="flex" vs-w="12">
          <vs-textarea
            width="100%"
            v-model="editAttendanceComment"
            placeholder="Comment"
          />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="9">
        </vs-col>
        <vs-col vs-type="flex" vs-justify="end" vs-align="end" vs-w="3">
          <vs-button
            color="primary"
            size="small"
            @click="EditAttendance()"
            type="filled"
            >Submit</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import constants from "../../constants.json";
export default {
  data() {
    return {
      attendanceComment: "",
      attendanceRadio: "",
      attendancePopup: false,
      startDate: null,
      endDate: null,
      selectedTeam: "",
      selectedWeekDays: [],
      spocs: {
        MWB: {
          members: [
            {
              name: "Naren",
              days: [
                {
                  date: "05-01-2020",
                  status: "present",
                  comment: "Naren attended",
                },
              ],
            },
            {
              name: "Suren",
              days: [
                {
                  date: "05-02-2020",
                  status: "present",
                  comment: "Suren attended",
                },
                {
                  date: "05-05-2020",
                  status: "left_early",
                  comment: "Suren left early",
                },
              ],
            },
            {
              name: "Haresh",
              days: [
                {
                  date: "05-02-2020",
                  status: "present",
                  comment: "Haresh attended",
                },
                {
                  date: "05-05-2020",
                  status: "left_early",
                  comment: "Haresh left early",
                },
                {
                  date: "05-07-2020",
                  status: "came_late",
                  comment: "Haresh came late",
                },
                {
                  date: "05-08-2020",
                  status: "left_early",
                  comment: "Haresh left ",
                },
              ],
            },
          ],
        },
        LMS: {
          members: [
            {
              name: "Aharon",
              days: [
                {
                  date: "05-05-2020",
                  status: "present",
                  comment: "Aharon attended",
                },
              ],
            },
            {
              name: "Satya",
              days: [
                {
                  date: "05-05-2020",
                  status: "left_early",
                  comment: "Satya left early",
                },
              ],
            },
            {
              name: "Naresh",
              days: [
                {
                  date: "05-12-2020",
                  status: "present",
                  comment: "Naresh attended",
                },
                {
                  date: "05-15-2020",
                  status: "left_early",
                  comment: "Naresh left early",
                },
                {
                  date: "05-17-2020",
                  status: "came_late",
                  comment: "Naresh came late",
                },
                {
                  date: "05-18-2020",
                  status: "came_late",
                  comment: "Naresh came late",
                },
              ],
            },
          ],
        },
        CMS: {
          members: [
            {
              name: "Yesh",
              days: [
                {
                  date: "05-16-2020",
                  status: "present",
                  comment: "Yesh attended",
                },
              ],
            },
            {
              name: "Rupas",
              days: [
                {
                  date: "05-16-2020",
                  status: "present",
                  comment: "Rupas attended",
                },
                {
                  date: "05-17-2020",
                  status: "left_early",
                  comment: "Rupas left early",
                },
              ],
            },
            {
              name: "Sarvesh",
              days: [
                {
                  date: "05-17-2020",
                  status: "present",
                  comment: "Sarvesh attended",
                },
                {
                  date: "05-18-2020",
                  status: "left_early",
                  comment: "Sarvesh left early",
                },
                {
                  date: "05-19-2020",
                  status: "came_late",
                  comment: "Sarvesh came late",
                },
                {
                  date: "05-20-2020",
                  status: "came_late",
                  comment: "Sarvesh came late",
                },
              ],
            },
          ],
        },
      },
      // selectedTeam: "",
      users: [],
      teamV_mmodel: "",
      teamList: [],
      team: [],
      // users: "",
      spocStrength: "",
      selectedDayslength: "",
      spocName: "",
      spocAttendanceDate: "",
      newSpocData: [],
      setStatus: "",
      setComment: "",
      selectedTeamUserId: "",
      teamName: "",
      teamID: "",
      editAttendanceRadio: "",
      editAttendancePopup: false,
      editSpocAttendanceDate: "",
      editAttendanceComment: "",
      editattendanceId: "",
    };
  },
  watch: {
    startDate(value) {
      this.startDate = value;
      this.endDate = this.getBetweenDays(this.startDate, 7);
      this.getUsersByTeam(this.teamV_mmodel);
      // console.log("start date", this.startDate + "- end date" + this.endDate);
      // this.getDates(this.startDate, this.endDate);
      // this.fetchData(this.startDate, this.endDate);
    },
    dateRange() {
      // this.fetchData();
    },
    teamV_mmodel(value) {
      // this.selectedTeam = value;
      // console.log("this.selectedTeam", this.selectedTeam);
      // let team = this.teamList.find((team) => team.name === this.selectedTeam);
      // console.log("this.team", team);
      this.getUsersByTeam(value);
      // this.teamName = team.name;
      // this.teamID = team.id;

      // this.fetchData(this.startDate, this.endDate);
    },
  },
  computed: {},
  mounted() {
    this.getTeams();
    this.startDate = new Date();
    this.endDate = this.getBetweenDays(this.startDate, 7);
    this.selectedWeekDays = [];
    // this.fetchData(this.startDate, this.endDate);
  },
  methods: {
    getIcon(status) {
      let icon = "cancel";
      switch (status) {
        case "absent":
          icon = "cancel";
          break;
        default:
          icon = "done";
          break;
      }
      return icon;
    },
    getColor(status) {
      let color = "danger";
      switch (status) {
        case "present":
          color = "green";
          break;
        case "came_late":
          color = "warning";
          break;
        case "left_early":
          color = "warning";
          break;
        case "half_day":
          color = "warning";
          break;
        case "absent":
          color = "danger";
          break;
      }
      return color;
    },
    fetchData(startDate, endDate) {
      let fromdate = this.datatoTimestamp(startDate);
      let todate = this.datatoTimestamp(endDate);
      // console.log("fstartdate",fromdate +"  and  fend date"+todate)
      console.log("this.spocs data", this.newSpocData);
      this.selectedWeekDays = [];
      // this.getUsersByTeam(teamName)
      this.getDates(new Date(fromdate * 1000), new Date(todate * 1000));
    },
    clear() {
      this.durationVmodel = null;
    },
    getBetweenDays(date, days) {
      console.log(days);
      var result = moment(date).add(7, "days");
      console.log("starrrtt dattee", result._d);
      return result._d;
    },
    getDates(startDate, stopDate) {
      var dateArray = [];
      var currentDate = moment(startDate);
      stopDate = moment(stopDate);
      while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format("MM-DD-YYYY"));
        currentDate = moment(currentDate).add(1, "days");
      }
      dateArray.shift();
      console.log("dateArray", dateArray);
      this.selectedWeekDays = dateArray;
      var spoc = [];
      for (let i = 0; i < this.selectedWeekDays.length; i++) {
        var newdate = this.selectedWeekDays[i];
        console.log("new date", newdate);
        console.log("new date", this.newSpocData);
        // spoc=[]
        for (let j = 0; j < this.newSpocData.length; j++) {
          spoc = this.newSpocData[j].days;
          //  if(spoc === ""){

          //  }
          console.log("spoc length", spoc);

          if (this.spocExists(newdate) === false) {
            // console.log('yes its not existed',newdate)
            spoc.push({
              attendance_date: newdate,
              status: "default",
              comment: "No Comment",
            });
          } else {
            // console.log('yes its existed',newdate)
          }
        }
      }

      console.log("get spocs in getdates", this.newSpocData);
    },
    spocExists() {
      // return spoc.some(function(el) {
      //   return el.attendance_date === newdate;
      // });
      return true;
    },
    customFormatter(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    customFormatterForHeader(date) {
      return moment(date).format("Do MMMM ");
    },
    onChange(item, newdate, set) {
      this.selectedTeamUserId = item.spoc_id;
      this.attendanceComment = set.comment;
      this.attendanceRadio = set.status;
      this.spocName = item.spoc_name;
      this.spocAttendanceDate = newdate;
      this.attendancePopup = true;
    },
    onChangeEdit(item, newdate, set) {
      this.editattendanceId = set.id;
      this.selectedTeamUserId = item.spoc_id;
      this.editAttendanceComment = set.comment;
      this.editAttendanceRadio = set.status;
      this.spocName = item.spoc_name;
      this.editSpocAttendanceDate = newdate;
      this.editAttendancePopup = true;
    },
    // -------------------------get Teams
    getTeams() {
      this.team = [];
      let url = `${constants.SERVER_API}team`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("team list", response.data.data);
          this.teamList = response.data.data;
          this.teamList.forEach((element) => {
            this.team.push({
              text: element.name,
              id: element.id,
            });
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getUsersByTeam(teamName) {
      this.newSpocData = [];
      // console.log(this.getStartDate(this.startDate));
      // console.log(this.getEndDate(this.endDate));
      let params = {
        team: teamName,
        start_date: this.getStartDate(this.startDate),
        end_date: this.getEndDate(this.endDate),
      };
      let url = `${constants.MILES_CM_BACK}getTeamBasedAttendance`;
      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status !== "success") {
            this.handleNotification(response);
          } else {
            this.generateData(response.data.data);
          }
          // console.log("team users", response.data.data);
          // let team_data = response.data.data;
          //  this.newSpocData = team_data;
          // team_data.forEach((element) => {
          //   this.newSpocData.push({
          //     id: element.id,
          //     name: element.first_name + " " + element.last_name,
          //     days: element.attendance,
          //   });
          // });
          // console.log(
          //   "startdatein get user",
          //   this.startDate + "----" + this.endDate
          // );
          // let newstartDate = this.startDate.getTime() - 24 * 60 * 60 * 1000;
          // let newenddate = this.endDate.getTime() - 24 * 60 * 60 * 1000;
          // this.getDates(newstartDate, newenddate);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    generateData(data) {
      this.selectedDates = this.getSelectedDates();
      let generatedData = [];

      data.forEach((spoc_data) => {
        let obj = {
          spoc_name: spoc_data.name,
          spoc_id: spoc_data.id,
          attendance: [],
        };

        spoc_data.attendance.forEach((attendance_obj) => {
          attendance_obj.formatted_date = moment
            .unix(attendance_obj.attendance_date)
            .format("DD-MM-YYYY");
        });

        this.selectedDates.forEach((selected_date) => {
          let objec = {
            date: selected_date,
            formatted_date: moment(selected_date).format("DD-MM-YYYY"),
            status: "absent",
            unix_timestamp: this.getStartDate(selected_date),
            comment: "",
            exists: 0,
            id: "",
          };

          spoc_data.attendance.forEach((attendance) => {
            if (attendance.formatted_date === objec.formatted_date) {
              objec.status = attendance.status;
              objec.unix_timestamp = attendance.attendance_date;
              objec.comment = attendance.comment;
              objec.id = attendance.id;
              objec.exists = 1;
            }
          });

          obj.attendance.push(objec);
        });

        generatedData.push(obj);
      });

      console.log(generatedData);
      this.newSpocData = generatedData;
    },

    formatDate(provided_date) {
      const d = provided_date;
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      console.log(`${da}-${mo}-${ye}`);
    },

    getSelectedDates() {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = this.startDate;
      const secondDate = this.endDate;

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };

      let dates = [];

      for (let index = 0; index < diffDays; index++) {
        let newDate = this.startDate.addDays(index);
        dates.push(newDate);
      }

      this.selectedWeekDays = dates;

      return dates;
    },

    //------------ add attendance to user
    addAttendance() {
      // console.log("add attendance");
      let leaveObj = {
        user_id: this.selectedTeamUserId,
        // team_id: this.teamID,
        // name: this.spocName,
        attendance_date: this.spocAttendanceDate,
        comment: this.attendanceComment,
        status: this.attendanceRadio,
        // hours_logged: null,
        // login_time: null,
        // logout_time: null,
      };
      console.log(leaveObj);
      // return;
      // if (leaveObj.status !== "default") {
      let url = `${constants.MILES_CM_BACK}attendance`;
      axios
        .post(url, leaveObj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.getUsersByTeam(this.teamV_mmodel);
          // this.getUsersByTeam(this.teamName);
          // this.fetchData(this.startDate, this.endDate);
          // this.$vs.notify({
          //   title: "Attendance",
          //   text: "Successfully Assigned",
          //   color: "success",
          // });
          this.attendancePopup = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
      // } else {
      //   this.$vs.notify({
      //     title: "Attendance",
      //     text: "Successfully Assigned",
      //     color: "success",
      //   });
      // }
    },
    EditAttendance() {
      // console.log("edit attendance");
      let leaveObj = {
        user_id: this.selectedTeamUserId,
        // team_id: this.teamID,
        // name: this.spocName,
        attendance_date: this.editSpocAttendanceDate,
        comment: this.editAttendanceComment,
        status: this.editAttendanceRadio,
        // hours_logged: null,
        // login_time: null,
        // logout_time: null,
      };
      console.log(leaveObj);
      // return;
      let url = `${constants.MILES_CM_BACK}attendance/${this.editattendanceId}`;
      axios
        .put(url, leaveObj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.getUsersByTeam(this.teamV_mmodel);
          // this.getUsersByTeam(this.teamName);
          // this.fetchData(this.startDate, this.endDate);
          // this.$vs.notify({
          //   title: "Attendance",
          //   text: "Successfully Updated",
          //   color: "success",
          // });
          this.editAttendancePopup = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
  components: {
    Datepicker,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/apps/simple-calendar.scss";
</style>
<style>
.vs-popup {
  max-width: 800px;
}
.reportrange-text {
  border-radius: 5px;
}
.gaps {
  margin-bottom: 4%;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1000;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 0px;
  border-width: 5px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}
.heading {
  color: gray;
  font-size: 15px;
  font-weight: bold;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
div.vs-popup {
  width: 300px;
}
.vdp-datepicker__calendar {
  z-index: 2000;
}
div.vs-table-text {
  /* justify-content: center; */
}
.vs-popup--title {
  justify-content: center;
  display: flex;
}
.tableHeader {
  border-radius: 5px;
  display: flex;
  margin: auto;
  width: 100%;
  background: #f2f2f2;
  height: 40px;
}

.popcontentrow {
  display: flex;
  width: 100%;
  border: 2px solid #f2f2f2;
  border-radius: 5px;
}
.con-vs-radio {
    margin: 5px;
}
</style>
